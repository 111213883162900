import React, { useState, useCallback, useEffect } from 'react'
import { useAuth } from 'src/AuthContext'
import GalaIcon from 'src/GalaIcon'

const UserWins: React.FC<{}> = () => {
  const { backendRequester } = useAuth()

  const [entries, setEntries] = useState<
    Awaited<ReturnType<typeof backendRequester.getMostRecentEntries>>
  >([])
  const [canFetchMore, setCanFetchMore] = useState<boolean>(false)
  const [visibleCount, setVisibleCount] = useState<number>(5)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchEntries = useCallback(
    async (offset = 0) => {
      const limit = 51

      setIsLoading(true)
      try {
        const results = await backendRequester.getMostRecentEntries({
          offset,
          limit,
        })
        const hasMore = results.length === limit
        const newEntries = results.slice(0, limit - 1)
        setEntries((prev) =>
          offset === 0 ? newEntries : [...prev, ...newEntries]
        )
        setCanFetchMore(hasMore)
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    },
    [backendRequester]
  )

  const handleLoadMore = useCallback(async () => {
    const newVisibleCount = visibleCount + 5
    if (newVisibleCount > entries.length && canFetchMore) {
      await fetchEntries(entries.length)
    }

    setVisibleCount(newVisibleCount)
  }, [visibleCount, entries.length, canFetchMore, fetchEntries])

  useEffect(() => {
    fetchEntries(0)
  }, [fetchEntries])

  return (
    <section className="winning-draws w-full pb-6">
      {/* <h3 className='text-xl font-bold text-white text-left flex flex-row flex-nowrap items-center justify-start gap-2'>
            Draw {drawId}
            <span className='text-sm text-lottoblue-400'>{endDate.toLocaleDateString()}</span>
        </h3> */}
      <div className="pt-4 overflow-x-auto w-full pb-4">
        {entries.length > 0 ? (
          <table className="animation-fade mt-2 w-full min-w-96 text-sm text-left text-lottoblue-400 border-spacing-4 border-0">
            <thead className="border-0 sr-only">
              <tr>
                <th className="font-bold text-center text-sm p-2 text-lottoblue-600 bg-lottoblue-700 border-r-8 border-lottoblue-900">
                  Ticket
                </th>
                <th className="font-bold text-center text-sm p-2 text-lottoblue-600 bg-lottoblue-700 relative">
                  Chosen Numbers
                </th>
              </tr>
            </thead>
            <tbody>
              {[...entries].slice(0, visibleCount).map((draw, index) => (
                <tr
                  key={index}
                  className="border-b border-lottoblue-700 last:border-b-0"
                >
                  <td className="py-4 text-center">
                    <div className="leading-none rounded-full px-5 py-2 font-bold text-lottoblue-900 bg-lottogreen flex items-center justify-between gap-1 flex-nowrap flex-1">
                      <span className="text-lottoblue-600">
                        Draw #{draw.drawing}
                      </span>
                      <span className="flex flex-row flex-nowrap items-center justify-start gap-1">
                        {draw.rewardAmount > 0 ? 'WIN' : false}{' '}
                        <GalaIcon className="w-6 h-6" />{' '}
                        {Math.floor(draw.rewardAmount)}
                      </span>
                    </div>
                  </td>
                  <td className="py-4 text-center">
                    <div className="ml-auto max-w-[196px] flex flex-row flex-wrap flex-1 items-center justify-start gap-1 px-4">
                      {draw.numbers
                        .sort((a, b) => a - b)
                        .map((number, index) => {
                          const isWinner = draw.winningNumbers.includes(number)
                          return (
                            <span
                              key={index}
                              className={`flex items-center justify-center rounded-full w-8 h-8 ${isWinner ? 'bg-lottogreen text-lottoblue-900 ticket-num-selected' : 'text-white bg-lottoblue-700'}`}
                            >
                              {number}
                            </span>
                          )
                        })}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : isLoading ? (
          <div className="w-full flex items-center justify-center">
            <div className="w-5 h-5 mx-auto border-2 border-t-transparent border-white rounded-full animate-spin"></div>
          </div>
        ) : (
          <p className="text-lottoblue-600 italic w-full text-center py-2">
            No Winning Results Currently
          </p>
        )}
      </div>

      {(visibleCount < entries.length || canFetchMore) && (
        <div className="w-full flex items-center justify-center mt-4">
          <button
            onClick={handleLoadMore}
            className="text-xs uppercase font-bold bg-transparent border border-lottoblue-600 text-lottoblue-400 px-6 py-1 rounded-full"
          >
            {isLoading ? (
              <div className="w-5 h-5 mx-auto border-2 border-t-transparent border-white rounded-full animate-spin"></div>
            ) : (
              'Load More'
            )}
          </button>
        </div>
      )}
    </section>
  )
}

export default UserWins
