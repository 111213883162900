import { FC, useEffect, useMemo, useState } from 'react'
import { CiCircleCheck } from 'react-icons/ci'
import { PageId } from 'src/types'
import { FaChevronLeft } from 'react-icons/fa'
import yellowTicket from 'src/assets/images/yellow-ticket.svg'
import ticketBackground from 'src/assets/images/ticket-background.svg'
import ticketGreenBlue from 'src/assets/images/ticket_icon_greenblue.png'
import TicketProgressBar from './TicketProgressBar'
import GalaIcon from 'src/GalaIcon'

import { MdErrorOutline } from 'react-icons/md'
import { useAuth } from 'src/AuthContext'
import { globalEvents } from 'src/events'
import { OverlayContainer } from 'src/UIComponents/OverlayContainer'
import GetTicketsModal from 'src/Dialog/GetTicketsModal'
import { footerLinks } from 'src/constants'
import { useGame } from 'src/Game/GameContext'

function chooseNumbersRandomly(existingNumbers: number[]): number[] {
  const newPendingNumbers = Array.from(existingNumbers)
  while (newPendingNumbers.length < 8) {
    const randomNum = Math.floor(Math.random() * 100) + 1
    if (!newPendingNumbers.includes(randomNum)) {
      newPendingNumbers.push(randomNum)
    }
  }

  return newPendingNumbers
}

const InsufficientGalaSection: FC<{}> = () => {
  return (
    <div
      className="bg-red-500 bg-opacity-10 border border-red-500 rounded-xl p-4 flex flex-col gap-4 mt-6 animate-pulse"
      style={{
        background: 'rgba(231, 29, 54, 0.10)',
        border: '1px solid rgba(231, 29, 54, 0.25)',
      }}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="text-red-500 text-xl">
            <MdErrorOutline />
          </span>
          <p className="text-white text-sm font-semibold self-center">
            Insufficient GALA
          </p>
        </div>
        <a
          rel="noreferrer"
          className="text-white font-normal text-sm px-4 py-2 rounded-full shadow-md bg-white bg-opacity-10"
          href="https://connect.gala.com/?viewProduct=66a8ed9e4d218b9606182ea7"
          target="_blank"
        >
          Get GALA
        </a>
      </div>

      <div className="flex flex-col items-center">
        <a
          rel="noreferrer"
          className="transition-colors text-white px-6 py-3 rounded-lg font-bold w-full text-center shadow-md bg-white bg-opacity-10"
          href="https://connect.gala.com/?viewProduct=66a8ed9e4d218b9606182ea7"
          target="_blank"
        >
          <span className="text-gradient-green-gold-horizontal">
            {/* How do gradient? Like dis ^ */}
            Buy Starter Pack to get $GALA ⚡
          </span>
          <p className="text-gray-400 text-xs font-normal mt-1">
            Credit cards available
          </p>
        </a>
      </div>
    </div>
  )
}

const ConfirmOverlay: FC<{
  ticketQuantity: number
  isSubmitting: boolean
  onClose: () => void
  onSubmit: () => void
}> = ({ ticketQuantity, isSubmitting, onClose, onSubmit }) => {
  const fee = ticketQuantity * 10
  const { useableGala } = useAuth()
  const insufficientGalaBalance = useableGala < fee

  return (
    <OverlayContainer isOpen={true} onClose={onClose}>
      <div className="flex items-center justify-between mb-4 px-2 pt-12 xs:pt-6">
        <div className="flex items-center gap-7">
          <div className="w-10 h-10 bg-lottogreen rounded-full flex items-center justify-center">
            <img src={ticketGreenBlue} alt="Lottery Ticket Icon" />
          </div>
          <div>
            <h1 className="text-white text-xl font-bold">Sweepstakes Ticket</h1>
            <p className="text-lottoblue-400 text-m">
              Quantity: {ticketQuantity}
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mb-2 px-2">
        <h2 className="text-white text-xl font-bold">Fees</h2>
        <p className="text-white text-lg font-semibold flex flex-row gap-2">
          <GalaIcon className="w-[1.75rem] h-[1.75rem]" />
          {fee}
        </p>
      </div>

      <div className="flex items-center justify-between mb-3 px-2">
        <h2 className="text-m text-white">Balance</h2>
        <p className="text-m text-white">{Math.trunc(useableGala)}</p>
      </div>

      <div className="w-full px-2">
        <p className="text-xs text-lottoblue-400">
          By submitting, you are agreeing to our{' '}
          <a
            className="underline hover:no-underline"
            target="_blank"
            rel="noreferrer"
            href={footerLinks.terms}
          >
            Terms and Conditions
          </a>
        </p>
      </div>

      {insufficientGalaBalance && <InsufficientGalaSection />}

      <div className="mt-8">
        <button
          disabled={insufficientGalaBalance}
          onClick={onSubmit}
          className="lottoCTA-blue py-3 text-sm w-full grow mb-4"
        >
          {isSubmitting ? (
            <div className="w-5 h-5 mx-auto border-2 border-t-transparent border-white rounded-full animate-spin"></div>
          ) : (
            'Proceed to Submit'
          )}
        </button>
      </div>
    </OverlayContainer>
  )
}

const NumberGridCard: FC<{
  currentTicketIndex: number
  pendingNumbers: number[]
  currentTicketIsInteractive: boolean
  handleNumberClick: (number: number) => void
}> = ({
  currentTicketIndex,
  pendingNumbers,
  currentTicketIsInteractive,
  handleNumberClick,
}) => {
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-1 items-baseline	">
          <span className="text-xs bg-lottoblue-650 px-8 py-1  rounded-full font-semibold">
            #{currentTicketIndex}
          </span>
          <h3 className="text-2xl font-bold">Pick 8 numbers</h3>
        </div>
        <TicketProgressBar total={8} progress={pendingNumbers.length} />
      </div>

      <div className="grid grid-cols-5 gap-2">
        {Array.from({ length: 100 }, (_, i) => i + 1).map((num) => (
          <button
            key={num}
            onClick={() => handleNumberClick(num)}
            className={`w-12 h-12 rounded-full font-bold text-xl place-self-center ${
              pendingNumbers.includes(num)
                ? 'bg-lottogreen text-lottoblue-900 ticket-num-selected'
                : 'text-lottoblue-400 ticket-num-default'
            } flex items-center justify-center transition-all`}
            disabled={!currentTicketIsInteractive}
          >
            {num}
          </button>
        ))}
      </div>
    </>
  )
}

const Header: FC<{
  changePage: (page: PageId) => void
  showGetTicketsOverlay: () => void
}> = ({ changePage, showGetTicketsOverlay }) => {
  const { currentDrawing, remainingEntriesPermitted } = useGame()
  return (
    <div className="mb-4 flex justify-between">
      <div>
        <button
          onClick={() => changePage('main')}
          className="bg-[#ffffff10] p-3 text-white rounded-full font-semibold"
        >
          <FaChevronLeft className="w-3 h-3" />
        </button>
      </div>
      <div className="text-center text-white mb-4">
        {currentDrawing && (
          <div className="flex flex-row items-center justify-center">
            <h2 className="text-xl font-bold mr-2">Draw{currentDrawing.id}</h2>
            <p className="text-sm self-center text-lottoblue-400">
              {currentDrawing.endDate.toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </p>
          </div>
        )}
        <p
          className={`text-s ${remainingEntriesPermitted === 0 ? 'text-red-500' : 'text-lottogreen'} font-light`}
        >
          {remainingEntriesPermitted} Tickets available
        </p>
      </div>
      <button className="h-10 relative block" onClick={showGetTicketsOverlay}>
        <img alt="" src={yellowTicket} className="h-full" />
        <p className="text-lottoblue-850 absolute left-0 top-1/2 -translate-y-1/2 w-full text-center text-xs font-bold">
          Get Tickets
        </p>
      </button>
    </div>
  )
}

export const TicketsScreen: FC<{
  changePage: (page: PageId) => void
}> = ({ changePage }) => {
  const { getInventory } = useAuth()
  const {
    entries: existingTickets,
    remainingEntriesPermitted,
    submitTickets,
    totalEntriesPermitted,
  } = useGame()
  const [currentTicketIndex, setCurrentTicketIndex] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [visibleOverlay, setVisibleOverlayState] = useState<
    false | 'confirm_tickets_modal' | 'get_tickets_modal'
  >(false)
  const [showDrawsSubmittedSuccess, setShowDrawsSubmittedSuccess] =
    useState(false)
  const [pendingNumbers, setPendingNumbers] = useState<number[][]>([[]])

  const numEntriesSubmitted = totalEntriesPermitted - remainingEntriesPermitted

  useEffect(() => {
    setCurrentTicketIndex(numEntriesSubmitted + 1)
  }, [numEntriesSubmitted])

  const setVisibleOverlay = (newValue: typeof visibleOverlay) => {
    if (newValue === false && visibleOverlay) {
      globalEvents.emit('navigate', 'tickets', visibleOverlay)
    }
    if (newValue) {
      globalEvents.emit('navigate', newValue, 'tickets')
    }

    setVisibleOverlayState(newValue)
  }

  const handleNumberClick = (number: number) => {
    setShowDrawsSubmittedSuccess(false)
    setPendingNumbers((prevPendingNumbers) => {
      const topmostPendingNumbers = Array.from(
        prevPendingNumbers[prevPendingNumbers.length - 1]
      )

      const isSelected = topmostPendingNumbers.includes(number)
      if (isSelected) {
        topmostPendingNumbers.splice(topmostPendingNumbers.indexOf(number), 1)
      } else {
        if (topmostPendingNumbers.length < 8) {
          topmostPendingNumbers.push(number)
        }
      }

      globalEvents.emit('buttonPress', 'numberSelect', {
        number,
        numbersForThisTicket: topmostPendingNumbers,
      })

      return [
        ...prevPendingNumbers.slice(0, prevPendingNumbers.length - 1),
        topmostPendingNumbers,
      ]
    })
  }

  const canConfirm = useMemo(
    () => pendingNumbers[pendingNumbers.length - 1].length === 8,
    [pendingNumbers]
  )

  const handleSelectRandom = () => {
    setShowDrawsSubmittedSuccess(false)
    setPendingNumbers((prevPendingNumbers) => {
      const topmostPendingNumbers =
        prevPendingNumbers[prevPendingNumbers.length - 1]
      const random = chooseNumbersRandomly(topmostPendingNumbers)

      globalEvents.emit('buttonPress', 'quickPickSingle', {
        numbersForThisTicketBefore: topmostPendingNumbers,
        numbersForThisTicket: random,
      })

      return [
        ...prevPendingNumbers.slice(0, prevPendingNumbers.length - 1),
        random,
      ]
    })
  }

  const handleSubmit = async () => {
    if (visibleOverlay !== 'confirm_tickets_modal') {
      return
    }

    setShowDrawsSubmittedSuccess(false)

    const tickets = pendingNumbers.map((numbers) => ({ numbers }))
    try {
      setIsSubmitting(true)
      await submitTickets(tickets)
      await getInventory()
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }

    globalEvents.emit('buttonPress', 'confirmSubmitTickets', {
      tickets: pendingNumbers.slice(0, 50),
      numTickets: pendingNumbers.length,
    })

    setShowDrawsSubmittedSuccess(true)
    setVisibleOverlay(false)
    setPendingNumbers([[]])
    setCurrentTicketIndex((prevIndex) => prevIndex + pendingNumbers.length)
  }

  const handleQuickPickMany = async () => {
    const numberToQuickPick = Math.min(remainingEntriesPermitted, 1000)
    const newTickets = Array(numberToQuickPick)
      .fill(0)
      .map(() => chooseNumbersRandomly([]))

    globalEvents.emit('buttonPress', 'quickPickMany', {
      tickets: newTickets.slice(0, 50),
      numTickets: newTickets.length,
    })

    setPendingNumbers(newTickets)
    setVisibleOverlay('confirm_tickets_modal')
  }

  const handleSubmitChosen = async () => {
    setPendingNumbers(pendingNumbers)
    setVisibleOverlay('confirm_tickets_modal')

    globalEvents.emit('navigate', 'confirm_tickets_modal', 'tickets')
  }

  const clear = () => {
    globalEvents.emit('buttonPress', 'clearNumberSelection')

    setPendingNumbers([[]])
  }

  const [isHTGTicketsVisible, setIisHTGTicketsVisible] = useState(false)

  const toggleHTGTickets = () => {
    globalEvents.emit('buttonPress', 'toggleGetTickets', {
      isHTGTicketsVisible: !isHTGTicketsVisible,
    })

    setIisHTGTicketsVisible(!isHTGTicketsVisible)
  }

  const canMultiQuickPick = remainingEntriesPermitted > 1
  const topmostPendingNumbers = pendingNumbers[pendingNumbers.length - 1]

  return (
    <div className="px-6 pt-14 max-w-lg w-full h-svh">
      <div>
        <Header
          showGetTicketsOverlay={() => toggleHTGTickets()}
          changePage={changePage}
        />

        {remainingEntriesPermitted === 0 && (
          <div className="relative mb-24 mt-24 text-lottoblue-400">
            <img alt="" src={ticketBackground} className="w-full" />
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              You have no ticket to play
            </span>
          </div>
        )}

        <div className="relative bg-lottoblue-850 rounded-3xl px-4 py-5 flex flex-col gap-4 ticket">
          <NumberGridCard
            currentTicketIndex={currentTicketIndex}
            pendingNumbers={topmostPendingNumbers}
            currentTicketIsInteractive={remainingEntriesPermitted > 0}
            handleNumberClick={handleNumberClick}
          />
        </div>
      </div>
      <GetTicketsModal
        open={isHTGTicketsVisible}
        onClose={() => toggleHTGTickets()}
      />
      <div
        style={{ transform: 'translateZ(0)' }}
        className="flex flex-col justify-between gap-4 py-4 sticky bottom-0 stickybottom z-10"
      >
        {remainingEntriesPermitted <= 0 && (
          <button
            className={`lottoCTA-green py-3 w-full`}
            onClick={() => toggleHTGTickets()}
          >
            Get Tickets
          </button>
        )}
        <div className="w-full flex flex-col gap-4">
          {showDrawsSubmittedSuccess && (
            <div className="text-white lotto-bottom-notification w-full justify-start flex flex-row">
              <span className="text-green-600">
                <CiCircleCheck />
              </span>
              Your picks have been submitted.
              <button
                className="lotto-close-button"
                aria-label="Close overlay"
                onClick={() => setShowDrawsSubmittedSuccess(false)}
              >
                ✕
              </button>
            </div>
          )}
          <div className="flex justify-between gap-4 w-full">
            {visibleOverlay === 'confirm_tickets_modal' && (
              <ConfirmOverlay
                isSubmitting={isSubmitting}
                ticketQuantity={pendingNumbers.length}
                onClose={() => setVisibleOverlay(false)}
                onSubmit={handleSubmit}
              />
            )}
            {!canConfirm && remainingEntriesPermitted > 0 && (
              <>
                <button
                  onClick={handleSelectRandom}
                  className={`lottoCTA-gray py-3 text-sm ${canMultiQuickPick ? 'w-1/2' : 'w-full'}`}
                  disabled={currentTicketIndex < existingTickets.length}
                >
                  Quick Pick
                </button>
                {canMultiQuickPick && (
                  <button
                    className="lottoCTA-blue py-3 text-sm w-1/2"
                    onClick={handleQuickPickMany}
                  >
                    QuickPick ({Math.min(remainingEntriesPermitted, 1000)})
                  </button>
                )}
              </>
            )}
            {!visibleOverlay && canConfirm && (
              <>
                <button
                  className="lottoCTA-gray py-3 text-sm px-8"
                  onClick={() => clear()}
                  disabled={currentTicketIndex < existingTickets.length}
                >
                  Clear
                </button>
                <button
                  className="lottoCTA-blue py-3 text-sm w-full grow"
                  onClick={handleSubmitChosen}
                  disabled={currentTicketIndex < existingTickets.length}
                >
                  Confirm
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
