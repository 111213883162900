import {
  createContext,
  type FC,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  type ICurrentDrawing,
  type IDrawing,
  type IDrawingEntry,
  type ITimeRemaining,
  UserType,
} from 'src/types'
import { useAuth } from 'src/AuthContext'
import { PubNubNotificationReceiver } from 'src/notifications'

interface GameContextType {
  currentDrawing: ICurrentDrawing | null
  entries: IDrawingEntry[]
  pastDrawings: IDrawing[]
  remainingEntriesPermitted: number
  submitTickets: (tickets: Array<{ numbers: number[] }>) => Promise<void>
  timeRemaining: ITimeRemaining
  totalEntriesPermitted: number
}

const GameContext = createContext<GameContextType | undefined>(undefined)

const useSessionId = (): string => {
    let sessionId = sessionStorage.getItem('session-id')
    if (!sessionId) {
      sessionId = crypto.randomUUID()
      sessionStorage.setItem('session-id', sessionId)
    }

  return sessionId
}

export const GameProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { backendRequester, userProfile } = useAuth()
  const [currentDrawing, setCurrentDrawing] = useState<ICurrentDrawing | null>(
    null
  )
  const [pastDrawings, setPastDrawings] = useState<IDrawing[]>([])
  const [entries, setEntries] = useState<IDrawingEntry[]>([])
  const [totalEntriesPermitted, setTotalEntriesPermitted] = useState(0)
  const [remainingEntriesPermitted, setRemainingEntriesPermitted] = useState(0)
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  })
  const sessionId = useSessionId()

  const fetchEntries = useCallback(async () => {
    try {
      if (userProfile && userProfile?.type !== UserType.AnonymousUser) {
        const entries = await backendRequester.getCurrentDrawingEntries()
        setEntries(entries.entries)
        setRemainingEntriesPermitted(entries.totalEntriesRemaining)
        setTotalEntriesPermitted(entries.totalEntriesPermitted)
      }
    } catch (err) {
      console.log(err)
    }
  }, [backendRequester, userProfile])

  const submitTickets = useCallback(
    async (tickets: Array<{ numbers: number[] }>) => {
      await backendRequester.submitTickets(tickets.map((t) => t.numbers))
      await fetchEntries()
    },
    [backendRequester, fetchEntries]
  )

  // Update time remaiming when the current drawing is updated
  useEffect(() => {
    if (!currentDrawing?.endDate) {
      return
    }

    const now = new Date()
    const diff = currentDrawing.endDate.getTime() - now.getTime()

    if (diff <= 0) {
      setTimeRemaining({ days: 0, hours: 0, minutes: 0 })
      return
    }

    setTimeRemaining({
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((diff / (1000 * 60)) % 60),
    })
  }, [currentDrawing?.endDate])

  // Fetch initial data
  useEffect(() => {
    async function fetchData() {
      const [currentDrawing, pastDrawings] = await Promise.all([
        backendRequester.getCurrentDrawing(),
        backendRequester.getPastDrawings(),
      ])
      setCurrentDrawing(currentDrawing)
      setPastDrawings(pastDrawings)
    }

    fetchData()

    // Handle live notifications for pot size changes
    const handlePotSizeChanged = async (newPotSize: number) => {
      setCurrentDrawing((prev) => {
        if (prev === null || prev?.potSize === newPotSize) {
          return prev
        }
        return {
          ...prev,
          potSize: newPotSize,
        }
      })
    }

    const notifications = new PubNubNotificationReceiver({ userId: sessionId })
    notifications.onPotSizeChanged(handlePotSizeChanged)

    // Update pot and countdown info every minute
    const intervalId = setInterval(async () => {
      const response = await backendRequester.getCurrentDrawing()
      setCurrentDrawing(response)
    }, 60000)

    return () => {
      notifications.offPotSizeChanged(handlePotSizeChanged)
      clearInterval(intervalId)
    }
  }, [backendRequester, sessionId])

  // Fetch initial entries
  useEffect(() => {
    fetchEntries()
  }, [fetchEntries])

  return (
    <GameContext.Provider
      value={{
        currentDrawing,
        entries,
        pastDrawings,
        remainingEntriesPermitted,
        submitTickets,
        timeRemaining,
        totalEntriesPermitted,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}

export const useGame = (): GameContextType => {
  const context = useContext(GameContext)
  if (!context) {
    throw new Error('useGame must be used within a GameProvider')
  }
  return context
}
