export const themeColors = {
  blueishpurple: {
    700: '#574eff',
  },
  brightamber: {
    400: '#ffba24',
    500: '#e39700',
  },
  cobaltblue: {
    700: '#3300ff',
  },
  deepblue: {
    50: '#b7bad4',
    300: '#a1a1c3',
    800: '#35356e',
    850: '#2b284f',
    900: '#191937',
    950: '#0a0c1c',
  },
  hotpink: {
    400: '#f066ff',
    600: '#ce2dff',
    650: '#c800ff',
    700: '#b70ceb',
  },
  neonlime: {
    50: '#f1ffea',
    300: '#9bffa3',
    500: '#86e044',
    550: '#9bfc53',
  },
  neutral: {
    450: '#9e9e9e',
    475: '#828282',
    750: '#373737',
    925: '#121212',
  },
  red: {
    550: '#e0191c',
    750: '#b1090c',
  },
  pinkishpurple: {
    400: '#b09ae9',
    700: '#8a24ff',
  },
  purplegray: {
    400: '#b6b6e3',
  },
  vividpurple: {
    700: '#7144e5',
  },
  lottoblue: {
    200: '#BCD8FF',
    400: '#9A9FC6',
    600: '#555873',
    650: '#211D67',
    700: '#1D1C33',
    850: '#0F0D30',
    900: '#020025',
  },
  gold: {
    500: '247 206 57', // #f7ce39
  },
  freedomgreen: {
    500: '0 178 90', // #00b25a
  },
  lottogreen: '#D9FF00',
  common: '#B3B3B3',
  uncommon: '#50c201',
  epic: '#D26FFF',
  legendary: '#FFD96E',
  bgbadgeprimary: '#140F6C',
  bgbadgesecondary: '#3A3A3A',
}

export const footerLinks = {
  terms: 'https://gala.com/legal/epic-gala-sweepstakes-terms-and-conditions',
  privacyPolicy: 'https://gala.com/legal/gala-privacy-policy',
}

export const galaTokenId = 'GALA|Unit|none|none'

export const galaToken = {
  additionalKey: 'none',
  category: 'Unit',
  collection: 'GALA',
  type: 'none',
  instance: '0',
}
export const socialShareLinkUrl = 'https://stage-galasweeper.needfulai.com'
export const socialShareMessage =
  'I just won {{amt}} in the Epic Gala Sweepstakes! Come Play and Win!'
