import { type FC, type ReactNode } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  type DialogProps,
} from '@headlessui/react'
import { HiXMark } from 'react-icons/hi2'

interface ResponsiveDialogProps extends DialogProps {
  children: ReactNode
  closeButton?: boolean
  closeButtonLabel?: string
  dialogTitle?: ReactNode
  dialogTitleTag?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'span'
    | 'div'
}

const ResponsiveDialog: FC<ResponsiveDialogProps> = ({
  children,
  closeButton = false,
  closeButtonLabel = 'Close',
  dialogTitle,
  dialogTitleTag = 'h3',
  ...props
}) => {
  const handleClose = () => props.onClose(false)
  return (
    <Dialog {...props} className={`relative z-20 ${props.className ?? ''}`}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-lottoblue-700/40 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed flex-grow flex flex-col items-center inset-0 z-20 w-screen overflow-y-auto sm:p-4">
        <DialogPanel
          transition
          className="relative flex-grow flex flex-col items-center justify-center w-full transform bg-lottoblue-900/50 shadow-lg ring-2 ring-white/10 backdrop-blur-2xl transition-all sm:flex-grow-0 sm:max-w-md sm:m-auto sm:rounded-[3rem] data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="p-6 relative flex-grow w-full max-w-xl overflow-hidden">
            <div className="relative">
              {closeButton && (
                <div className="-mt-1">
                  <button
                    onClick={handleClose}
                    className="flex items-center justify-center rounded-full bg-white/10 hover:bg-white/20 transition p-4 ml-auto mr-0"
                  >
                    <HiXMark
                      className="w-4 h-4"
                      aria-hidden="true"
                      role="img"
                    />
                    <span className="sr-only">Close profile</span>
                  </button>
                </div>
              )}
              {dialogTitle && (
                <DialogTitle
                  as={dialogTitleTag}
                  className={`text-2xl font-bold mb-4 ${closeButton ? '' : 'mt-4'}`}
                >
                  {dialogTitle}
                </DialogTitle>
              )}
              {children}
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}

export default ResponsiveDialog
