import { type FC } from 'react'
import { ITimeRemaining } from 'src/types'

const NextDrawingCountdown: FC<{
  forceColumn?: boolean
  timeRemaining: ITimeRemaining
}> = ({ forceColumn, timeRemaining }) => {
  return (
    <div
      className={`bg-gradient-to-t from-lottogreen/30 via-white/15 to-white/15 w-full mx-auto grid ${forceColumn ? 'grid-cols-1 text-center' : 'grid-cols-1 sm:grid-cols-2'} items-center p-4 my-8 rounded-2xl gap-4`}
    >
      <p className="text-2xl xs:text-xl font-bold whitespace-nowrap text-gradient-green-white-horizontal">
        Draw Closes in
      </p>
      <div className="w-full max-w-72 mx-auto grid grid-cols-3 text-3xl font-black gap-4">
        <div className="flex flex-col gap-1">
          {timeRemaining.days}{' '}
          <span className="rounded-full text-[8px] py-1 font-normal leading-none text-lottoblue-750 bg-white bg-opacity-10 mix-blend-screen">
            DAYS
          </span>
        </div>
        <div className="flex flex-col gap-1">
          {timeRemaining.hours}{' '}
          <span className="rounded-full text-[8px] py-1 font-normal leading-none text-lottoblue-750 bg-white bg-opacity-10 mix-blend-screen">
            HOURS
          </span>
        </div>
        <div className="flex flex-col gap-1">
          {timeRemaining.minutes}{' '}
          <span className="rounded-full text-[8px] py-1 font-normal leading-none text-lottoblue-750 bg-white bg-opacity-10 mix-blend-screen">
            MIN
          </span>
        </div>
      </div>
    </div>
  )
}

export default NextDrawingCountdown
