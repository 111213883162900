import { type FC, type Ref, forwardRef } from 'react'
import styles from './lottoMarquee.module.css'

interface IProps {
  className?: string
  potSize?: number
  tokenUsdValue?: number
}

const Ellipsis: FC = () => (
  <p aria-hidden="true" className="ellipsis text-[2rem] leading-none h-6">
    *
  </p>
)

const LottoMarqueePotSizeUsd: FC<IProps> = forwardRef(
  ({ className, potSize = 0, tokenUsdValue = 0 }, ref: Ref<HTMLDivElement>) => {
    const usdValue = tokenUsdValue * potSize
    const formattedValue = usdValue.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    })
    return (
      <div ref={ref} className={`bg-lottoblue-900 ${className ?? ''}`}>
        <div
          className={`${styles.flickerItem} relative text-transparent text-[clamp(2rem,12vw,4rem)] xs2:text-[4rem] leading-[4rem] h-[4rem] font-black flex items-center justify-center`}
        >
          <div className="bg-goldenGradient bg-clip-text flex items-center justify-center gap-1">
            <p>${formattedValue}</p>
            <Ellipsis />
          </div>
          <div className="pointer-events-none absolute top-0.5 left-0 w-full h-full flex items-center justify-center gap-1 text-black [text-shadow:0_-2px_0_var(--tw-shadow-color)] shadow-white/75 mix-blend-screen">
            <p aria-hidden="true">${formattedValue}</p>
            <Ellipsis />
          </div>
          <div className="pointer-events-none absolute bottom-0.5 left-0 w-full h-full flex items-center justify-center gap-1 text-black [text-shadow:0_2px_0_var(--tw-shadow-color)] shadow-[#D8FF00]/60 mix-blend-screen">
            <p aria-hidden="true">${formattedValue}</p>
            <Ellipsis />
          </div>
        </div>
      </div>
    )
  }
)

export default LottoMarqueePotSizeUsd
