import { type FC } from 'react'
import LottoMarqueeLights from './LottoMarqueeLights'
import LottoMarqueeNumbers from './LottoMarqueeNumbers'
import NormalPlayBodySvg from './NormalPlayBodySvg'
import NormalPlayTopSvg from './NormalPlayTopSvg'

const NormalPlay: FC<{ potSize: number }> = ({ potSize }) => {
  return (
    <div className="relative z-1">
      <NormalPlayTopSvg className="relative z-[3] block mx-auto w-[11.43%] [filter:drop-shadow(0px_5.755px_5.755px_rgba(0,0,0,0.35))] mb-[-3.5%]" />
      <div className="relative">
        <NormalPlayBodySvg className="w-[1000px] max-w-full h-auto" />
        <div className="absolute z-[2] left-0 bottom-0 h-full w-full p-[7%]">
          <LottoMarqueeNumbers potSize={potSize} />
        </div>
        <div className="w-full absolute bottom-0 left-0">
          <LottoMarqueeLights />
        </div>
      </div>
    </div>
  )
}

export default NormalPlay
