import { type FC, useEffect, useState } from 'react'
import GalaIcon from 'src/GalaIcon'
import { useGame } from 'src/Game/GameContext'

interface IPrizePool {
  matches: number
  prizePool: number
  typeLabel: string
  bgClass: string
}

enum RewardType {
  FixedReward = 'FixedReward',
  SharedPercentageOfPot = 'SharedPercentageOfPot',
}

const rewardForNumMatches: Record<
  number,
  {
    rewardType: RewardType
    value: number
  }
> = {
  8: {
    rewardType: RewardType.SharedPercentageOfPot,
    value: 0.5,
  },
  7: {
    rewardType: RewardType.SharedPercentageOfPot,
    value: 0.2,
  },
  6: {
    rewardType: RewardType.SharedPercentageOfPot,
    value: 0.15,
  },
  5: {
    rewardType: RewardType.FixedReward,
    value: 200_000,
  },
  4: {
    rewardType: RewardType.FixedReward,
    value: 2_000,
  },
  3: {
    rewardType: RewardType.FixedReward,
    value: 200,
  },
  2: {
    rewardType: RewardType.FixedReward,
    value: 10,
  },
  1: {
    rewardType: RewardType.FixedReward,
    value: 0,
  },
  0: {
    rewardType: RewardType.FixedReward,
    value: 0,
  },
}

const CurrentPools: FC = () => {
  const { currentDrawing } = useGame()
  const currentPotSize = currentDrawing?.potSize ?? 0
  const [currWinnings, setCurrWinnings] = useState<IPrizePool[]>([])
  const NUM_WINNING_NUMBERS = 8

  useEffect(() => {
    const currentResults = []
    for (let i = 0; i < NUM_WINNING_NUMBERS + 1; i++) {
      const rewardDefinition = rewardForNumMatches[i]

      const obj = {
        matches: i,
        typeLabel:
          rewardDefinition.rewardType === RewardType.FixedReward
            ? 'Fixed'
            : 'Shared',
        bgClass:
          rewardDefinition.rewardType === RewardType.FixedReward
            ? 'bg-bgbadgesecondary'
            : 'bg-bgbadgeprimary',
        prizePool:
          rewardDefinition.rewardType === RewardType.FixedReward
            ? rewardDefinition.value
            : currentPotSize * rewardDefinition.value,
      }
      currentResults.push(obj)
    }

    setCurrWinnings(currentResults.reverse())
  }, [currentPotSize])

  return (
    <section className="past-draws w-full py-6 relative">
      <h2 className="text-2xl font-bold text-white text-left mb-4">
        See What You Can Win
      </h2>
      <span
        aria-label="Live Updates"
        aria-hidden="true"
        className="animate-bounce-centered font-normal z-10 absolute top-18 left-1/2 sm:left-[73%] transform -translate-x-1/2 origin-center p-2 px-3 whitespace-nowrap leading-none text-xs bg-purple-800 text-white rounded-full"
      >
        Live Updates
      </span>

      <div className="pt-4 w-full pb-6 mb-4 overflow-x-auto">
        {currentPotSize > 0 ? (
          <table className="animation-fade mt-2 w-full min-w-96 text-sm text-left text-lottoblue-400 border-spacing-4 border-0">
            <thead className="border-0">
              <tr>
                <th className="font-bold text-center text-sm p-2 text-lottoblue-600 bg-lottoblue-700 border-r-8 border-lottoblue-900">
                  Matches
                </th>
                <th className="font-bold text-center text-sm p-2 text-lottoblue-600 bg-lottoblue-700 border-r-8 border-lottoblue-900">
                  Reward
                </th>
              </tr>
            </thead>
            <tbody>
              {[...currWinnings]
                .filter((x) => x.matches >= 2)
                .map((tier, index) => (
                  <tr
                    key={index}
                    className={`${
                      index <
                      [...currWinnings].filter((x) => x.matches >= 2).length - 1
                        ? 'border-b border-lottoblue-700'
                        : ''
                    }`}
                  >
                    <td className="py-4 text-center">Match {tier.matches}</td>
                    <td className="py-4 text-center">
                      <div className="relative flex items-center justify-between sm:justify-center gap-2">
                        <div className="flex items-center gap-2">
                          <GalaIcon className="w-4 h-4" />
                          {Math.round(tier.prizePool).toLocaleString()}
                        </div>
                        <span
                          className={`sm:absolute right-0 ${tier.bgClass} text-lottoblue-400 text-xs font-medium px-2.5 py-1 rounded-md mr-3 uppercase`}
                        >
                          {tier.typeLabel}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <span className="text-gray-500 italic">No data available</span>
        )}
      </div>
      <div className="w-full p-4 rounded-lg bg-gray-900 mb-8">
        <p className="text-center text-sm text-lottoblue-400">
          Match at least 1 number for a{' '}
          <span className="text-lottogreen font-bold">free ticket</span> in the
          next drawing!
        </p>
      </div>
    </section>
  )
}

export default CurrentPools
