import {
  createContext,
  useCallback,
  useContext,
  useState,
  type FC,
  type ReactNode,
} from 'react'
import { type DialogId, type DialogConfig } from './types'

export type OpenDialog = <T extends DialogId>(
  name: T,
  props?: DialogConfig<T>['props']
) => void

export interface DialogContextType<T extends DialogId = DialogId> {
  activeDialog: DialogConfig<T> | undefined
  closeDialog: () => void
  openDialog: OpenDialog
}

const DialogContext = createContext<DialogContextType | undefined>(undefined)

export const DialogProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [activeDialog, setActiveDialog] = useState<DialogConfig<DialogId>>()

  const openDialog = useCallback<OpenDialog>((name, props) => {
    setActiveDialog((prev) => {
      const newDialog = {
        name,
        props: props || {},
      }
      // If there's already an active dialog, close it first
      if (prev?.name) {
        // Open the new dialog after close animation
        setTimeout(() => {
          setActiveDialog(newDialog)
        }, 150)
        // Unset to close the previous dialog
        return undefined
      }
      // Set the new dialog as active
      return newDialog
    })
  }, [])

  const closeDialog = useCallback(() => {
    setActiveDialog(undefined)
  }, [])

  return (
    <DialogContext.Provider
      value={{
        activeDialog,
        closeDialog,
        openDialog,
      }}
    >
      {children}
    </DialogContext.Provider>
  )
}

export const useDialogContext = (): DialogContextType => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error(
      'useDialogContext must be used within an DialogContextProvider'
    )
  }
  return context
}
