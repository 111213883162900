import {
  type ComponentType,
  lazy,
  Suspense,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDialogContext } from './DialogContext'
import { type DialogConfig, type DialogId, type DialogMap } from './types'

/**
 * Global Dialogs
 * To add a new dialog, follow these steps:
 *
 * 1. Add a new entry to the DialogMap in src/Dialog/types.ts
 *    If necessary, add a new interface for the new dialog's props.
 * 2. Create your new dialog component. Note the dialog should at least
 *    accept the following props:
 *    - open: boolean
 *    - onClose: () => void
 * 3. Add a new entry to the dialog registry (dialogComponents) in this file
 *    with the lazy import of your new dialog component. Note that the component
 *    must be default exported.
 */

// Dialog registry
const dialogComponents: {
  [K in DialogId]: ComponentType<DialogMap[K]>
} = {
  // these must use default exports to work with lazy
  alert: lazy(() => import('./AlertModal')),
  burn: lazy(() => import('./BurnModal')),
  login: lazy(() => import('./LoginModal')),
  profile: lazy(() => import('./ProfileModal')),
  wallet: lazy(() => import('./CreateWalletModal')),
}

const GlobalDialogs = () => {
  const { activeDialog, closeDialog } = useDialogContext()
  const [localActiveDialog, setLocalActiveDialog] = useState<
    DialogConfig<DialogId> | undefined
  >(activeDialog)
  const timeoutId = useRef<ReturnType<typeof setTimeout>>()
  const pendingUpdate = useRef<() => void>()

  const renderDialog = (dialogId: DialogId) => {
    const DialogComponent = dialogComponents[dialogId]
    return DialogComponent ? (
      <DialogComponent
        {...localActiveDialog?.props}
        open={activeDialog?.name === dialogId}
        onClose={closeDialog}
      />
    ) : null
  }

  useEffect(() => {
    // after a dialog closes, unset local state and call any pending updates
    const onAfterDialogClose = () => {
      setLocalActiveDialog(undefined)
      if (pendingUpdate.current) {
        pendingUpdate.current()
        pendingUpdate.current = undefined
      }
    }

    // Due to how the dialog context works, `activeDialog` will
    // always go from undefined -> defined -> undefined --
    // it forces the previous dialog to close before setting a new active one
    setLocalActiveDialog((prev) => {
      // If the dialog is closing, handle updates once the close transition is finished
      if (!activeDialog) {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current)
        }
        timeoutId.current = setTimeout(() => {
          onAfterDialogClose()
          timeoutId.current = undefined
        }, 150)
        return prev
      }
      // If there is a new active dialog and the previous dialog is still closing,
      // set the new active dialog as a pending update
      if (timeoutId.current) {
        pendingUpdate.current = () => {
          setLocalActiveDialog(activeDialog)
        }
        return prev
      }
      // Set the new active dialog immediately if no transition is pending
      return activeDialog
    })
  }, [activeDialog])

  return (
    <>
      {Object.keys(dialogComponents).map((key) => (
        <Suspense key={key}>{renderDialog(key as DialogId)}</Suspense>
      ))}
    </>
  )
}

export default GlobalDialogs
