import { type FC } from 'react'
import { AuthProvider } from 'src/AuthContext'
import { GameProvider } from 'src/Game/GameContext'
import { TokenProvider } from 'src/TokenContext'
import { DialogProvider } from 'src/Dialog/DialogContext'
import GlobalDialogs from 'src/Dialog/GlobalDialogs'
import GameScreen from 'src/Game/GameScreen'
import LottoWidget from 'src/UIComponents/LottoWidget'
import 'src/galaAnalytics'
import { Web3Provider } from './Web3Context'

const App: FC = () => {
  const pathname = window.location.pathname
  const isCountdown = /countdown/.test(pathname)

  return (
    <div
      className={`app flex-grow flex flex-col w-full items-center min-h-full h-svh overflow-x-hidden relative`}
    >
      {isCountdown && <CountdownPage />}
      {!isCountdown && <HomePage />}
    </div>
  )
}

const CountdownPage: FC = () => {
  return (
    <TokenProvider>
      <LottoWidget refreshInterval={60000} />
    </TokenProvider>
  )
}

const HomePage: FC = () => {
  return (
    <>
      {/* <SoundProvider> */}
      <DialogProvider>
      <Web3Provider>
        <AuthProvider>
          <TokenProvider>
            <GameProvider>
              <GameScreen />
              <GlobalDialogs />
            </GameProvider>
          </TokenProvider>
        </AuthProvider>
        </Web3Provider>
      </DialogProvider>
      {/* </SoundProvider> */}
    </>
  )
}

export default App
