import { type FC, useEffect, useRef, useState } from 'react'
import { Transition } from '@headlessui/react'
import { useTokenContext } from 'src/TokenContext'
import LottoMarqueePotSizeUsd from './LottoMarqueePotSizeUsd'
import LottoMarqueePotSizeGala, { type IRef } from './LottoMarqueePotSizeGala'

interface IProps {
  maxDigits?: number
  potSize: number
}

const LottoMarqueeNumbers: FC<IProps> = ({
  potSize: currentPotSize,
  maxDigits = 7,
}) => {
  const [showUsdValue, setShowUsdValue] = useState(false)
  const isNewCycle = useRef(false)
  const [potSize, setPotSize] = useState(currentPotSize)
  const { galaToken } = useTokenContext()
  const galaNumbersRef = useRef<IRef>(null)
  const intervalId = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    setPotSize(currentPotSize)
  }, [currentPotSize])

  useEffect(() => {
    if (!intervalId.current) {
      intervalId.current = setInterval(() => {
        setShowUsdValue((prev) => !prev)
        if (!isNewCycle.current) {
          isNewCycle.current = true
        }
      }, 6000)
    }
  }, [potSize])

  useEffect(() => {
    if (!showUsdValue && isNewCycle.current) {
      setTimeout(() => {
        if (currentPotSize === potSize) {
          galaNumbersRef.current?.replayAnimation()
        } else {
          setPotSize(currentPotSize)
        }
      }, 500)
      isNewCycle.current = false
    }
  }, [showUsdValue, currentPotSize, potSize])

  return (
    <>
      <div className="h-full overflow-hidden p-[2.5%]">
        <div
          className={`h-full mb-4 transition duration-500 ${showUsdValue ? 'translate-y-0' : '-translate-y-[calc(100%_+_1rem)]'}`}
        >
          <LottoMarqueePotSizeUsd
            potSize={potSize}
            tokenUsdValue={galaToken?.currentPrices.usd}
            className={`rounded-lg h-full flex items-center justify-center ${showUsdValue ? '' : '[animation-play-state:paused]'}`}
          />
        </div>
        <div
          className={`h-full transition duration-500 ${showUsdValue ? 'translate-y-0' : '-translate-y-[calc(100%_+_1rem)]'}`}
        >
          <LottoMarqueePotSizeGala
            ref={galaNumbersRef}
            maxDigits={maxDigits}
            potSize={potSize}
            animateAll={true}
          />
        </div>
      </div>
      <Transition show={showUsdValue}>
        <div className="absolute font-medium -bottom-4 left-1/2 w-full -translate-x-1/2 text-xs text-lottoblue-400 transition duration-500 delay-500 data-[closed]:opacity-0 data-[closed]:delay-0">
          <p>
            * Price values from{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.coingecko.com/"
              className="text-white font-semibold"
            >
              CoinGecko
            </a>
          </p>
        </div>
      </Transition>
    </>
  )
}

export default LottoMarqueeNumbers
