import { type FC, useState } from 'react'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import { globalEvents } from 'src/events'
import { useGame } from 'src/Game/GameContext'
import GalaIcon from 'src/GalaIcon'

const PastDrawingsCarousel: FC = () => {
  const { pastDrawings: drawings } = useGame()
  const [currentDrawIndex, setCurrentDrawIndex] = useState(0)

  const handleNextDraw = () => {
    if (currentDrawIndex > 0) {
      setCurrentDrawIndex(currentDrawIndex - 1)
      globalEvents.emit('buttonPress', 'viewNextDraw')
    }
  }

  const handlePreviousDraw = () => {
    if (drawings?.length && currentDrawIndex < drawings.length - 1) {
      setCurrentDrawIndex(currentDrawIndex + 1)
      globalEvents.emit('buttonPress', 'viewPreviousDraw')
    }
  }

  const pastDrawSelected = drawings[currentDrawIndex]
  if (!drawings?.length) {
    return null
  }
  return (
    <section className="past-draws w-full py-6">
      <h2 className="text-2xl font-bold text-white text-left mb-8">
        Past Draws
      </h2>
      <div className="carousel-container mx-auto mt-8 w-full overflow-hidden">
        {drawings.length > 0 ? (
          <div className="flex gap-4">
            {pastDrawSelected && (
              <div key={pastDrawSelected.id} className="p-4 w-full shrink-0">
                <div className="w-full grid grid-cols-4 xl:grid-cols-past-draw-nav place-items-center justify-items-center max-w-96 mx-auto mb-4">
                  <button
                    onClick={handleNextDraw}
                    className="carousel-nav-btn w-9 h-9 rounded-full bg-lottoblue-700 text-white hover:bg-lottoblue-650 flex justify-center items-center"
                    disabled={currentDrawIndex === 0}
                  >
                    <MdKeyboardArrowLeft />
                  </button>

                  <header className="col-span-2 xl:col-span-1 text-xl leading-none font-semibold flex flex-col xs:flex-row items-center justify-center gap-3">
                    Draw {pastDrawSelected.id}
                    <span className="block text-sm font-normal text-lottoblue-400 leading-none">
                      {new Date(pastDrawSelected.endDate).toLocaleDateString()}
                    </span>
                  </header>

                  <button
                    onClick={handlePreviousDraw}
                    className="carousel-nav-btn w-9 h-9 rounded-full bg-lottoblue-700 text-white hover:bg-lottoblue-650 flex items-center justify-center"
                    disabled={currentDrawIndex === drawings.length - 1}
                  >
                    <MdKeyboardArrowRight />
                  </button>
                </div>
                <div className="py-3">
                  <div className="flex flex-nowrap items-center justify-around gap-[5px] max-w-96 mx-auto">
                    {pastDrawSelected.winningNumbers.length > 0 ? (
                      pastDrawSelected.winningNumbers
                        .sort((a, b) => a - b)
                        .map((number, index) => (
                          <div
                            key={index}
                            style={{ animationDelay: `${index * 150 + 50}ms` }}
                            className={`animation-lotto-bubble bg-lottogreen text-lottoblue-900 font-bold w-6 h-6 text-xs xs:w-9 xs:h-9 rounded-full xs:text-sm flex justify-center items-center`}
                          >
                            {number.toString()}
                          </div>
                        ))
                    ) : (
                      <span className="text-gray-500 italic">
                        {pastDrawSelected.status === 'pending'
                          ? 'Pending...'
                          : 'No Numbers'}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-4 overflow-x-auto w-full overflow-y-hidden pb-4">
                  {pastDrawSelected.winnerTiers.length > 0 ? (
                    <table className="animation-fade mt-2 w-full min-w-96 text-sm text-left text-lottoblue-400 border-spacing-4 border-0">
                      <thead className="border-0">
                        <tr>
                          <th className="font-bold text-center text-xs p-2 text-lottoblue-600 bg-lottoblue-700 border-r-8 border-lottoblue-900">
                            Matches
                          </th>
                          <th className="font-bold text-center text-xs p-2 text-lottoblue-600 bg-lottoblue-700 border-r-8 border-lottoblue-900">
                            Winners
                          </th>
                          <th className="font-bold text-center text-xs p-2 text-lottoblue-600 bg-lottoblue-700">
                            Prize Allocation
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pastDrawSelected.winnerTiers
                          .filter((t) => t.numbersMatched !== 0)
                          .map((tier, index) => (
                            <tr
                              key={index}
                              className={`${index < pastDrawSelected.winnerTiers.length - 1 ? 'border-b border-lottoblue-700' : ''}`}
                            >
                              <td className="py-3 text-center">
                                Match {tier.numbersMatched}
                              </td>
                              <td className="py-3 text-center">
                                {tier.numWinners.toLocaleString()}
                              </td>
                              <td className="py-3">
                                <div className="flex flex-row flex-nowrap items-center justify-center gap-2">
                                  <GalaIcon className="w-4 h-4" />{' '}
                                  {Math.floor(tier.actualTotalGalaReward)}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <span className="text-gray-500 italic">
                      No data available
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center text-gray-500">Loading drawings...</div>
        )}
      </div>
    </section>
  )
}

export default PastDrawingsCarousel
