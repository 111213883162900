export enum UserType {
  GalaPlatformGameJwt = 'GalaPlatformGameJwt',
  AnonymousUser = 'AnonymousUser',
  Web3Wallet = 'Web3Wallet',
}

export interface IUserProfile {
  avatar: string
  displayName: string
  type: UserType
  walletAddress?: string; //Only relevant in the case of web3wallet session
}

export interface IDrawing {
  id: number
  status: 'completed' | 'pending'
  startDate: string
  endDate: string
  winnerTiers: Array<{
    numbersMatched: number
    numWinners: number
    actualTotalGalaReward: number
  }>
  winningNumbers: number[]
}

export interface ICurrentDrawing {
  id: number
  burnAmount: number
  entryCount: number
  potSize: number
  endDate: Date
}

export interface IAllDrawingEntry {
  drawing: number
  id: number
  rewardAmount: number
  numbers: number[]
  winningNumbers: number[]
}

export interface IDrawingEntry {
  id: number
  numWinningNumber: number
  rewardAmount: number
  numbers: number[]
  winningNumbers: number[]
}

export interface IUserInventoryItem {
  itemId: string
  totalQuantity: number
  useableQuantity: number
}

export type UserInventory = IUserInventoryItem[]

export interface IWeb3Login {
  message: 'Login to Gala Sweepstakes'
  date: string
}

export interface ISignedWeb3Login extends IWeb3Login {
  signature: string
}
export interface ITimeRemaining {
  days: number
  hours: number
  minutes: number
}

export interface CreateWalletDTO {
  signature: string
  prefix: string
  uniqueKey: string
}

export type PageId = 'main' | 'tickets'

// only defining the properties we need
export interface IToken {
  additionalKey: string
  category: string
  collection: string
  currentPrices: {
    usd: number
    usd24hChange: number
    lastUpdatedAt: string
  }
  decimals: number
  description: string
  image: string
  name: string
  priceIsKnownToExternalPriceSource: boolean
  stringifiedTokenClassKey: string
  symbol: string
  type: string
}
