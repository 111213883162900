import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
  type ReactNode,
} from 'react'
import { BackendRequester } from 'src/backend_requests'
import {
  ISignedWeb3Login,
  IWeb3Login,
  UserType,
  type IUserProfile,
  type UserInventory,
} from 'src/types'
import { globalEvents } from 'src/events'
import { galaTokenId } from 'src/constants'
import { useDialogContext } from 'src/Dialog/DialogContext'
import { useWeb3 } from './Web3Context'
import { ErrorCode } from './types/error'

interface AuthContextType {
  backendRequester: BackendRequester
  isPendingAllowances: boolean
  isPendingAuth: boolean
  isPendingInventory: boolean
  isPendingProfile: boolean
  galaAllowances?: '0' | 'Infinity'
  getInventory: () => void
  getAllowances: () => Promise<void>
  logIn: () => void
  logOut: () => Promise<void>
  userIsRegistered: boolean
  logInAuth0: () => void
  logInWeb3: () => Promise<void>
  useableGala: number
  userInventory: UserInventory
  userProfile: IUserProfile | undefined
  connectedUserAlias?: string
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

function getAnalyticsUserIdFromNamespacedUserId(
  namespacedUserId: string
): string {
  // We only do this for backwards compatibility with analytics
  // that we've already sent before.
  return namespacedUserId.replace('gala_platform_', '')
}

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const isMounted = useRef(false)
  const [isPendingAuth, setIsPendingAuth] = useState(false)
  const [authToken, setAuthToken] = useState<string | null>(null)
  const { activeDialog, openDialog, closeDialog } = useDialogContext()
  const [isPendingProfile, setIsPendingProfile] = useState(false)
  const [isPendingInventory, setIsPendingInventory] = useState(false)
  const [isPendingAllowances, setIsPendingAllowances] = useState(false)
  const signedLogin = useRef<ISignedWeb3Login | null>(null)

  const [userInventory, setUserInventory] = useState<UserInventory>([])
  const [galaAllowances, setGalaAllowances] = useState<
    '0' | 'Infinity' | undefined
  >(undefined)

  const [userIsRegistered, setUserIsRegistered] = useState<boolean>(false)

  const [userProfile, setUserProfile] = useState<IUserProfile | undefined>(
    undefined
  )
  const [connectedUserAlias, setConnectedUserAlias] = useState<string>()

  const { connectWeb3, signWeb3, connectedWallet } = useWeb3()

  const backendRequester = useMemo(() => {
    if (authToken) {
      return new BackendRequester(UserType.GalaPlatformGameJwt, {
        userJwt: authToken,
      })
    } else if (signedLogin.current) {
      return new BackendRequester(UserType.Web3Wallet, {
        web3Login: signedLogin.current,
      })
    } else {
      return new BackendRequester(UserType.AnonymousUser)
    }
  }, [authToken])

  const useableGala = useMemo(
    () =>
      userInventory.find((item) => item.itemId === galaTokenId)
        ?.useableQuantity ?? 0,
    [userInventory]
  )

  const logIn = () => {
    if (process.env.REACT_APP_ENABLE_WEB3 === 'true') {
      openDialog('login')
    } else {
      logInAuth0()
    }
  }

  const logInAuth0 = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_SSO_URI}/game-burn-allowance?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      process.env.REACT_APP_REDIRECT_URI ?? ''
    )}&includeToken`
  }, [])

  const logInWeb3 = useCallback(async () => {
    const address = await connectWeb3()
    const loginVerification: IWeb3Login = {
      date: new Date().toISOString(),
      message: 'Login to Gala Sweepstakes',
    }
    let success = false
    try {
      if (!signedLogin.current) {
        signedLogin.current = await signWeb3('Login', loginVerification)
      }
      const isRegistered = await backendRequester.isRegistered(address)
      if (!isRegistered) {
        throw new Error(ErrorCode.UNKNOWN_ERROR)
      } else if (!isRegistered?.exists) {
        throw new Error(ErrorCode.GALACHAIN_WALLET_NOT_FOUND)
      }
      const authedBackendRequester = new BackendRequester(UserType.Web3Wallet, {
        web3Login: signedLogin.current,
      })
      setUserIsRegistered(isRegistered.exists)
      await authedBackendRequester.login()

      setIsPendingProfile(true)

      const profile = await backendRequester.getUserProfile()
      if (profile?.userProfile) {
        setUserProfile({
          ...profile.userProfile,
          walletAddress: profile.namespacedUserId.replace('w3w_', 'eth|'),
          type: profile.type,
        })
        if (profile.type !== UserType.AnonymousUser) {
          globalEvents.emit(
            'authedAsUser',
            getAnalyticsUserIdFromNamespacedUserId(profile.namespacedUserId)
          )
        }
      }

      setIsPendingProfile(false)
      success = true
    } catch (e) {
      if ((e as Error)?.message === ErrorCode.GALACHAIN_WALLET_NOT_FOUND) {
        openDialog('wallet', { onSuccess: logInWeb3 })
      } else {
        throw e
      }
    }
    if (success) {
      openDialog('burn')
    }
  }, [backendRequester, connectWeb3, openDialog, signWeb3])

  const logOut = useCallback(async () => {
    await backendRequester.logout()
    globalEvents.emit('loggedOut')

    window.location.reload()
  }, [backendRequester])

  const getInventory = useCallback(async () => {
    setIsPendingInventory(true)
    if (backendRequester) {
      const inventory = await backendRequester.getInventory()
      setUserInventory(inventory.inventory)
    }
    setIsPendingInventory(false)
  }, [backendRequester])

  const getAllowances = useCallback(async () => {
    setIsPendingAllowances(true)
    if (backendRequester) {
      const response = await backendRequester.getAllowances()
      setGalaAllowances(response.allowances)
    }
    setIsPendingAllowances(false)
  }, [backendRequester])

  useEffect(() => {
    async function getConnectedWalletAlias() {
      if (connectedWallet !== ''){
        const registrationInfo =
        await backendRequester.isRegistered(connectedWallet)
        setConnectedUserAlias(registrationInfo?.walletAlias)
      }
    }
    getConnectedWalletAlias()
  }, [backendRequester, connectedWallet])

  useEffect(() => {
    async function doInitialAuth() {
      // Workaround for React calling the effect twice in dev mode.
      // That causes a race condition since the server will issue
      // two separate cookies but only the second will be valid,
      // but the browser might end up using the first.
      if (isMounted.current) {
        return
      }

      isMounted.current = true

      globalEvents.emit('navigate', 'main')

      setIsPendingAuth(true)

      if (window.location.search) {
        const params = new URLSearchParams(window.location.search)

        const utmParams = Array.from(params).filter(([key]) =>
          key.startsWith('utm_')
        )
        const utmParamString = utmParams
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
        if (utmParamString) {
          localStorage.setItem('utmParams', utmParamString)
        }

        const token = params.get('token')
        const success = params.get('success')

        if (success === 'true') {
          setAuthToken(token)
          const authedBackendRequester = new BackendRequester(
            UserType.GalaPlatformGameJwt,
            {
              userJwt: token,
            }
          )
          await authedBackendRequester.login()
        }

        if (success || token) {
          params.delete('token')
          params.delete('success')
          window.history.replaceState({}, '', `?${params.toString()}`)
        }
      } else {
        const hasSession = await backendRequester.hasSession()
        if (!hasSession) {
          await backendRequester.login()
        }
      }

      setIsPendingAuth(false)

      setIsPendingProfile(true)

      const profile = await backendRequester.getUserProfile()
      if (profile?.userProfile) {
        setUserProfile({
          ...profile.userProfile,
          walletAddress: profile.namespacedUserId.replace('w3w_', 'eth|'),
          type: profile.type,
        })
        if (profile.type !== UserType.AnonymousUser) {
          globalEvents.emit(
            'authedAsUser',
            getAnalyticsUserIdFromNamespacedUserId(profile.namespacedUserId)
          )
        }
      }

      setIsPendingProfile(false)
    }

    doInitialAuth()
  }, [backendRequester, isMounted])

  useEffect(() => {
    if (userProfile) {
      getInventory()
      if (userProfile.type === UserType.Web3Wallet) {
        getAllowances()
      }
    }
  }, [userProfile, getInventory, getAllowances])

  useEffect(() => {
    if (galaAllowances === '0' && userProfile?.type === UserType.Web3Wallet) {
      openDialog('burn')
    } else {
      if (activeDialog?.name === 'burn') {
        closeDialog()
      }
    }
  }, [
    galaAllowances,
    userProfile?.type,
    activeDialog?.name,
    closeDialog,
    openDialog,
  ])

  useEffect(() => {
    if (galaAllowances === '0' && userProfile?.type === UserType.Web3Wallet) {
      openDialog('burn')
    } else {
      if (activeDialog?.name === 'burn') {
        closeDialog()
      }
    }
  }, [
    galaAllowances,
    userProfile?.type,
    activeDialog?.name,
    closeDialog,
    openDialog,
  ])

  return (
    <AuthContext.Provider
      value={{
        backendRequester,
        galaAllowances,
        getAllowances,
        getInventory,
        isPendingAllowances,
        isPendingAuth,
        isPendingInventory,
        isPendingProfile,
        logIn,
        logInAuth0,
        logInWeb3,
        logOut,
        useableGala,
        userInventory,
        userProfile,
        userIsRegistered,
        // getIsRegistered,
        connectedUserAlias,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
