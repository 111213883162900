import { type FC, useCallback, useState } from 'react'
import Header from 'src/Header'
import { PageId } from 'src/types'
import { MainScreen } from './Main'
import { TicketsScreen } from './Tickets'
import { globalEvents } from 'src/events'
import { useGame } from 'src/Game/GameContext'

const GameScreen: FC = () => {
  const [page, setPageState] = useState<PageId>('main')
  const { currentDrawing } = useGame()

  const setPage = useCallback(
    (newPage: PageId) => {
      globalEvents.emit('navigate', newPage, page)
      setPageState(newPage)
    },
    [page]
  )

  const handleChangePage = (newPage: PageId) => {
    setPage(newPage)
  }

  return (
    <>
      <div className="absolute top-0 left-0 w-full z-[5] overflow-hidden">
        <div className="max-w-screen-2xl mx-auto">
          <Header />
        </div>
      </div>
      {page === 'main' && currentDrawing && (
        <MainScreen changePage={handleChangePage} />
      )}
      {page === 'tickets' && currentDrawing && (
        <TicketsScreen changePage={handleChangePage} />
      )}
    </>
  )
}

export default GameScreen
