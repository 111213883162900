import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  type FC,
  type ReactNode,
} from 'react'
import { IToken } from 'src/types'

interface TokenContextType {
  galaToken: IToken | undefined
  isPendingToken: boolean
}

interface IData {
  tokens: IToken[]
}

const TokenContext = createContext<TokenContextType | undefined>(undefined)

export const TokenProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isPendingToken, setIsPendingToken] = useState(false)
  const [galaToken, setGalaToken] = useState<IToken | undefined>(undefined)
  const intervalId = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        'https://api-galaswap.gala.com/v1/tokens?symbols=GALA'
      )
      const data = (await response.json()) as IData
      const gala = data.tokens.find((t) => t.symbol === 'GALA')
      if (gala) {
        setGalaToken(gala)
      }
    }
    const fetchInitialData = async () => {
      setIsPendingToken(true)
      try {
        await fetchData()
      } catch (e) {
        console.error(e)
      } finally {
        setIsPendingToken(false)
      }
    }

    fetchInitialData()

    if (intervalId.current) {
      clearInterval(intervalId.current)
    }
    intervalId.current = setInterval(fetchData, 60000)

    return () => {
      clearInterval(intervalId.current)
    }
  }, [])

  return (
    <TokenContext.Provider
      value={{
        isPendingToken,
        galaToken,
      }}
    >
      {children}
    </TokenContext.Provider>
  )
}

export const useTokenContext = (): TokenContextType => {
  const context = useContext(TokenContext)
  if (!context) {
    throw new Error('useTokenContext must be used within a TokenProvider')
  }
  return context
}
