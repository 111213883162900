import { type FC, useState } from 'react'
import { useGame } from 'src/Game/GameContext'
import yellowTicket from 'src/assets/images/yellow-ticket.svg'
import { globalEvents } from 'src/events'
import GetTicketsModal from 'src/Dialog/GetTicketsModal'

const UserEntries: FC = () => {
  const { currentDrawing, entries: currentDraws } = useGame()
  const endDate = currentDrawing?.endDate
  const drawId = currentDrawing?.id ?? 0
  const [isHTGTicketsVisible, setIisHTGTicketsVisible] = useState(false)

  const toggleHTGTickets = () => {
    globalEvents.emit('buttonPress', 'toggleGetTickets', {
      isHTGTicketsVisible: !isHTGTicketsVisible,
    })

    setIisHTGTicketsVisible(!isHTGTicketsVisible)
  }

  const [visibleCount, setVisibleCount] = useState<number>(5)
  const visibleDraws = [...currentDraws].slice(0, visibleCount)

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 5) // Load 5 more rows
  }

  return (
    <section className="winning-draws w-full py-6">
      <GetTicketsModal
        open={isHTGTicketsVisible}
        onClose={() => toggleHTGTickets()}
      />
      <div className="w-full flex items-center justify-between flex-nowrap">
        <h3 className="text-xl font-bold text-white text-left flex flex-col xs:flex-row flex-nowrap items-center justify-start gap-2">
          Draw {drawId}
          {endDate && (
            <span className="text-sm text-lottoblue-400">
              {endDate.toLocaleDateString()}
            </span>
          )}
        </h3>
        <button
          className="h-10 relative block"
          onClick={() => toggleHTGTickets()}
        >
          <img
            alt=""
            aria-hidden="true"
            src={yellowTicket}
            className="h-full"
          />
          <p className="text-lottoblue-850 absolute left-0 top-1/2 -translate-y-1/2 w-full text-center text-xs font-bold">
            Get Tickets
          </p>
        </button>
      </div>
      <div className="pt-4 overflow-x-auto w-full pb-4">
        {currentDraws.length > 0 ? (
          <table className="animation-fade mt-2 w-full min-w-96 text-sm text-left text-lottoblue-400 border-spacing-4 border-0">
            <thead className="border-0 sr-only">
              <tr>
                <th className="font-bold text-center text-sm p-2 text-lottoblue-600 bg-lottoblue-700 border-r-8 border-lottoblue-900">
                  Ticket
                </th>
                <th className="font-bold text-center text-sm p-2 text-lottoblue-600 bg-lottoblue-700 relative">
                  Chosen Numbers
                </th>
              </tr>
            </thead>
            <tbody>
              {visibleDraws.map((draw, index) => (
                <tr
                  key={index}
                  className={`${index < visibleDraws.length - 1 ? 'border-b border-lottoblue-700' : ''}`}
                >
                  <td className="py-4 text-center">
                    <span className="leading-none rounded-full px-5 py-2 text-white bg-lottoblue-650">
                      #{draw.id}
                    </span>
                  </td>
                  <td className="py-4 text-center">
                    <div className="flex flex-row items-center justify-center gap-2 px-4">
                      {draw.numbers
                        .sort((a, b) => a - b)
                        .map((number, index) => (
                          <span
                            key={index}
                            className="flex items-center justify-center rounded-full w-10 h-10 text-white bg-lottoblue-700"
                          >
                            {number}
                          </span>
                        ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-lottoblue-600 italic w-full text-center py-2">
            No Entries Currently
          </p>
        )}
      </div>

      {visibleCount < currentDraws.length && (
        <div className="w-full flex items-center justify-center mt-4">
          <button
            onClick={handleLoadMore}
            className="text-xs uppercase font-bold bg-transparent border border-lottoblue-600 text-lottoblue-400 px-6 py-1 rounded-full"
          >
            Load More
          </button>
        </div>
      )}
    </section>
  )
}

export default UserEntries
