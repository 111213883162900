import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styles from './lottoMarquee.module.css'

interface IPotSizeData {
  key: number // Used to force a re-render when the pot size changes
  currentValue: string
  previousValue: string
}

interface INumberTileProps {
  className?: string
  previousValue: string
  reverseIndex: number // The index from the end of the marquee
  value: string
}

export interface IProps {
  animateAll?: boolean
  className?: string
  maxDigits?: number
  potSize?: number
}

export interface IRef {
  replayAnimation: () => void
}

const NumberTile = forwardRef<HTMLDivElement, INumberTileProps>(
  ({ className, previousValue, reverseIndex, value }, ref) => {
    const oneBasedReverseIndex = reverseIndex + 1
    return (
      <div
        ref={ref}
        className={`${styles.marqueeNumber} ${oneBasedReverseIndex % 3 === 0 ? 'ml-1' : ''} ${
          className ?? ''
        }`}
      >
        <p
          style={{
            animationDelay: `${oneBasedReverseIndex / 4 + 1.1}s`,
          }}
          className={`current-number ${styles.anim3}`}
        >
          {value}
        </p>
        <p
          style={{
            animationDelay: `${oneBasedReverseIndex / 4 + 0.25}s`,
          }}
          className={`${styles.questionMark} ${styles.anim2}`}
        >
          ?
        </p>
        <p
          style={{
            animationDelay: `${oneBasedReverseIndex / 4}s`,
          }}
          className={`${styles.prevNumber} ${styles.anim1}`}
        >
          {previousValue}
        </p>
      </div>
    )
  }
)

const LottoMarqueePotSizeGala = forwardRef<IRef, IProps>(
  ({ animateAll, className, maxDigits = 7, potSize = 0 }, ref) => {
    const prevPotSize = useRef(potSize)
    const [newPotSize, setNewPotSize] = useState(potSize)
    const [potSizeData, setPotSizeData] = useState(() => {
      const numberString = prevPotSize.current
        .toString()
        .padStart(maxDigits, '0')
      return numberString.split('').map((char) => {
        const initData: IPotSizeData = {
          key: 0,
          currentValue: char,
          previousValue: '0',
        }
        return initData
      })
    })

    const handleUpdatePotSize = useCallback(() => {
      const diffLength = Math.abs(newPotSize - prevPotSize.current).toString()
        .length
      const padAmount = Math.max(maxDigits, newPotSize.toString().length)
      const previousValueFormatted = prevPotSize.current
        .toString()
        .padStart(padAmount, '0')
      const currentValueFormatted = newPotSize
        .toString()
        .padStart(padAmount, '0')

      setPotSizeData((prev) => {
        return currentValueFormatted.split('').map((char, index) => {
          const reverseIndex = -index + currentValueFormatted.length - 1
          const prevChar = previousValueFormatted?.[index] ?? '0'
          const hasChanged =
            animateAll || diffLength > reverseIndex || prevChar !== char
          return {
            key: hasChanged ? prev[index].key + 1 : prev[index].key,
            currentValue: char,
            previousValue: prevChar,
          }
        })
      })
    }, [animateAll, maxDigits, newPotSize])

    useImperativeHandle(
      ref,
      () => ({
        replayAnimation: () => {
          setPotSizeData((prev) => {
            return prev.map((data) => ({
              key: data.key + 1,
              previousValue: data.currentValue,
              currentValue: data.currentValue,
            }))
          })
        },
      }),
      []
    )

    useEffect(() => {
      setNewPotSize(Math.floor(potSize ?? 0))
    }, [potSize])

    useEffect(() => {
      if (newPotSize === prevPotSize.current) {
        return
      }
      handleUpdatePotSize()
      prevPotSize.current = newPotSize
    }, [newPotSize, maxDigits, handleUpdatePotSize])

    return (
      <div
        className={`w-full h-full flex justify-stretch gap-1 sm:gap-1.5 ${className ?? ''}`}
      >
        {potSizeData.map((data: IPotSizeData, index) => (
          <NumberTile
            key={`${index}-${data.key}`}
            reverseIndex={-index + potSizeData.length - 1}
            value={data.currentValue}
            previousValue={data.previousValue}
            className={
              potSizeData.length <= 7
                ? 'text-4xl sm:text-[2.75rem]'
                : 'text-3xl sm:text-4xl'
            }
          />
        ))}
      </div>
    )
  }
)

export default LottoMarqueePotSizeGala
