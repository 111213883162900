import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
  type FC,
} from 'react'
import { BrowserConnectClient } from '@gala-chain/connect'
import { useCallback } from 'react'
import { ErrorCode } from './types/error'
interface Web3ContextType {
  connectedWallet: string
  isAwaitingSign: boolean
  connectWeb3: () => Promise<string>
  isAwaitingConnect: boolean
  signWeb3: <T extends object>(
    method: string,
    payload: T
  ) => Promise<
    T & {
      signature: string
      prefix: string
    }
  >
}

const Web3Context = createContext<Web3ContextType | undefined>(undefined)

export const Web3Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isAwaitingSign, setIsAwaitingSign] = useState(false)

  const [connectedWallet, setConnectedWallet] = useState('')

  const connectClient = useMemo(() => {
    try {
      return new BrowserConnectClient()
    } catch (error) {
      console.warn(ErrorCode.WEB3_PROVIDER_NOT_FOUND, error)
      return null
    }
  }, [])

  const [isAwaitingConnect, setIsAwaitingConnect] = useState(false)

  async function isConnected() {
    if (window.ethereum) {
      try {
        // Request the list of accounts
        const accounts = await window.ethereum.request({
          method: 'eth_accounts',
        })

        if (accounts.length > 0) {
          // Wallet is unlocked, and the user is connected
          const currentWallet = (accounts[0] as string)
          setConnectedWallet(currentWallet)
          return currentWallet
        } else {
          //Wallet is unlocked but no account selected.
        }
      } catch (err) {
        console.error('Error checking accounts:', err)
      }
    } else {
      console.log(ErrorCode.WEB3_PROVIDER_NOT_FOUND)
    }
  }

  const connectWeb3 = useCallback(async () => {
    if (!connectClient) throw new Error(ErrorCode.WEB3_PROVIDER_NOT_FOUND)
    const currentWallet = await isConnected()
    if (currentWallet) {
      return currentWallet
    }
    setIsAwaitingConnect(true)
    try {
      const wallet = await connectClient.connect()
      setConnectedWallet(wallet)
      setIsAwaitingConnect(false)
      return wallet
    } catch (e) {
      console.error(e)
      setIsAwaitingConnect(false)
      return ''
    }
  }, [connectClient])

  const signWeb3 = useCallback(
    async <T extends object>(
      method: string,
      payload: T
    ): Promise<
      T & {
        signature: string
        prefix: string
      }
    > => {
      if (!connectClient) throw new Error(ErrorCode.WEB3_PROVIDER_NOT_FOUND)

      const currentWallet = await isConnected()
      if (!currentWallet) {
        await connectWeb3()
      } else {
        //Needed, but won't show prompt
        await connectClient.connect()
      }
      try {
        setIsAwaitingSign(true)
        const signedResult = await connectClient.sign(method, payload)
        setIsAwaitingSign(false)

        return signedResult
      } catch (e) {
        console.error(e)
        setIsAwaitingSign(false)
        throw e
      }
    },
    [connectClient, connectWeb3]
  )

  return (
    <Web3Context.Provider
      value={{
        isAwaitingSign,
        connectedWallet,
        connectWeb3,
        isAwaitingConnect,
        signWeb3,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

export const useWeb3 = (): Web3ContextType => {
  const context = useContext(Web3Context)
  if (!context) {
    throw new Error('useWeb3 must be used within a Web3 provider')
  }
  return context
}
